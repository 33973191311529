exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loyalty-and-rewards-programs-js": () => import("./../../../src/pages/loyalty-and-rewards-programs.js" /* webpackChunkName: "component---src-pages-loyalty-and-rewards-programs-js" */),
  "component---src-pages-mobile-checkout-scan-n-thru-js": () => import("./../../../src/pages/mobile-checkout-scan-n-thru.js" /* webpackChunkName: "component---src-pages-mobile-checkout-scan-n-thru-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-who-we-serve-js": () => import("./../../../src/pages/who-we-serve.js" /* webpackChunkName: "component---src-pages-who-we-serve-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog-categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-5-reasons-mobile-self-checkout-saves-retail-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/5-reasons-mobile-self-checkout-saves-retail-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-5-reasons-mobile-self-checkout-saves-retail-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-self-checkout-in-grocery-stores-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/benefits-of-self-checkout-in-grocery-stores/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-self-checkout-in-grocery-stores-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-customer-loyalty-and-mobile-self-checkout-in-grocery-stores-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/customer-loyalty-and-mobile-self-checkout-in-grocery-stores/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-customer-loyalty-and-mobile-self-checkout-in-grocery-stores-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-easy-guide-to-grocery-store-self-checkout-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/easy-guide-to-grocery-store-self-checkout/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-easy-guide-to-grocery-store-self-checkout-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-scan-and-pay-for-retailers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/guide-to-scan-and-pay-for-retailers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-scan-and-pay-for-retailers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-mobile-self-chekout-outperforms-other-checkout-systems-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-mobile-self-chekout-outperforms-other-checkout-systems/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-mobile-self-chekout-outperforms-other-checkout-systems-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-scan-and-pay-technology-is-changing-retail-payments-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-scan-and-pay-technology-is-changing-retail-payments/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-scan-and-pay-technology-is-changing-retail-payments-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-scan-and-thru-transforms-shopping-experience-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-scan-and-thru-transforms-shopping-experience/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-scan-and-thru-transforms-shopping-experience-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-implement-self-checkout-in-retail-stores-steps-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/implement-self-checkout-in-retail-stores-steps/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-implement-self-checkout-in-retail-stores-steps-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-improve-customer-experience-in-shopping-malls-with-scan-and-go-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/improve-customer-experience-in-shopping-malls-with-scan-and-go/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-improve-customer-experience-in-shopping-malls-with-scan-and-go-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-based-loyalty-program-in-self-checkout-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/mobile-based-loyalty-program-in-self-checkout/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-based-loyalty-program-in-self-checkout-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-self-checkout-for-fashion-retail-stores-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/mobile-self-checkout-for-fashion-retail-stores/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-self-checkout-for-fashion-retail-stores-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-self-checkout-guide-for-pharmacies-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/mobile-self-checkout-guide-for-pharmacies/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-mobile-self-checkout-guide-for-pharmacies-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-must-have-features-of-scan-to-pay-technology-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/must-have-features-of-scan-to-pay-technology/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-must-have-features-of-scan-to-pay-technology-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-overcome-retail-checkout-challenges-with-mobile-self-checkout-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/overcome-retail-checkout-challenges-with-mobile-self-checkout/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-overcome-retail-checkout-challenges-with-mobile-self-checkout-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-retail-mobile-self-checkout-things-to-know-before-implementation-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/retail-mobile-self-checkout-things-to-know-before-implementation/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-retail-mobile-self-checkout-things-to-know-before-implementation-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-revolutionize-retail-future-with-self-checkout-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/revolutionize-retail-future-with-self-checkout/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-revolutionize-retail-future-with-self-checkout-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-scan-pay-and-go-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/role-of-scan-pay-and-go/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-scan-pay-and-go-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-scan-and-pay-the-secured-method-for-contactless-shopping-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/scan-and-pay-the-secured-method-for-contactless-shopping/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-scan-and-pay-the-secured-method-for-contactless-shopping-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-scan-and-thru-solutions-for-retail-stores-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/scan-and-thru-solutions-for-retail-stores/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-scan-and-thru-solutions-for-retail-stores-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-self-checkout-for-gift-stores-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/self-checkout-for-gift-stores/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-self-checkout-for-gift-stores-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-self-checkout-kiosks-vs-mobile-self-checkout-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/self-checkout-kiosks-vs-mobile-self-checkout/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-self-checkout-kiosks-vs-mobile-self-checkout-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-self-checkout-technology-retail-competitive-edge-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/self-checkout-technology-retail-competitive-edge/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-self-checkout-technology-retail-competitive-edge-index-mdx" */)
}

